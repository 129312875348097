<!-- eslint-disable vue/no-v-html -->
<template>
    <table class="gridWrapper">
        <caption v-if="!hideCaption" class="pa-2">
            <slot :caption="content.caption" name="caption">{{ content.caption }}</slot>
        </caption>
        <thead>
            <tr>
                <template v-for="(header, index) of content.headers" :key="index + header">
                    <th
                        v-if="checkHeaderExists(index)"
                        v-bind="getHeaderSpanAttributes(header)"
                        :class="cellStyleClass(header)"
                    >
                        <slot :header="header" :index="index" name="headerCell" v-html="extractCellText(header)" />
                    </th>
                </template>
            </tr>
        </thead>
        <tbody>
            <tr v-for="row of content.rows" :key="row">
                <template v-for="col of content.cols" :key="col">
                    <td
                        v-if="checkCellExists(row - 1, col - 1)"
                        v-bind="getCellSpanAttributes(row - 1, col - 1)"
                        :class="cellStyleClass(content.grid[row - 1][col - 1])"
                    >
                        <slot
                            :value="content.grid[row - 1][col - 1]"
                            :col="col - 1"
                            :row="row - 1"
                            name="bodyCell"
                            v-html="extractCellText(content.grid[row - 1][col - 1])"
                        />
                    </td>
                </template>
            </tr>
        </tbody>
    </table>
</template>

<script>
import { extractCellText } from './cellOperations';
import { cellStyleClass } from './cellStyleClass';

export default {
    name: 'GridWrapper',

    props: {
        content: {
            type: Object,
            required: true,
        },

        hideCaption: {
            type: Boolean,
            default: false,
        },
    },

    methods: {

        getCellSpanAttributes(row, col) {
            if (this.content.grid[row][col].colSpan) {
                return {
                    colspan: this.content.grid[row][col].colSpan,
                };
            }

            if (this.content.grid[row][col].rowSpan) {
                return {
                    rowspan: this.content.grid[row][col].rowSpan,
                };
            }
        },

        getHeaderSpanAttributes(header) {
            if (this.content.headers.find(x => x === header)?.colSpan) {
                return {
                    colspan: this.content.headers.find(x => x === header)?.colSpan,
                };
            }
        },

        isCellNotSpannedCol(row, col, grid) {
            let prevColSpanIndex = -1;
            for (let i = col; i >= 0; i--) {
                if (grid[row][i].colSpan !== undefined) {
                    prevColSpanIndex = i;
                    break;
                }
            }
            if (prevColSpanIndex > -1 && prevColSpanIndex !== col) {
                return prevColSpanIndex + grid[row][prevColSpanIndex].colSpan <= col;
            }

            return true;
        },

        isCellNotSpannedRow(row, col, grid) {
            let prevRowSpanIndex = -1;
            for (let i = row; i >= 0; i--) {
                if (grid[i][col].rowSpan !== undefined) {
                    prevRowSpanIndex = i;
                    break;
                }
            }
            if (prevRowSpanIndex > -1 && prevRowSpanIndex !== row) {
                return prevRowSpanIndex + grid[prevRowSpanIndex][col].rowSpan <= row;
            }

            return true;
        },

        checkCellExists(row, col) {
            const grid = this.content.grid;

            return this.isCellNotSpannedCol(row, col, grid) && this.isCellNotSpannedRow(row, col, grid);
        },

        checkHeaderExists(index) {
            const headers = this.content.headers;

            let prevColSpanIndex = -1;
            for (let i = index; i >= 0; i--) {
                if (headers[i].colSpan !== undefined) {
                    prevColSpanIndex = i;
                    break;
                }
            }
            if (prevColSpanIndex > -1 && prevColSpanIndex !== index) {
                return prevColSpanIndex + headers[prevColSpanIndex].colSpan <= index;
            }

            return true;
        },

        extractCellText,
        cellStyleClass,
    },
};
</script>

<style scoped lang="stylus">
@require './gridEditor.styl';
.gridWrapper {
    width: 100%;
    margin: 0 auto;
    border: 1px solid $gridBorderColor;
    border-collapse: collapse;

    vertical-align: middle;

    caption {
        caption-side: top;
        background-color: #2e73b6;
        color: white;
        text-align: center;
    }

    td, th {
        max-width: 3em;
        border: 1px solid $gridBorderColor;
        text-align: center;
        padding: 10px;
    }

    th {
        background-color: #f8f8f8;
        font-weight: normal;
    }

    td {
        background-color: white;
    }

    .italic {
        font-style: italic;
    }

    .bold {
        font-weight: bold;
    }
}
</style>
